var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"border-top border-bottom p-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group"},[_c('small',{staticClass:"text-sm text-uppercase font-semibold"},[_vm._v("Başlangıç")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  enableTime: true,
                  altFormat: 'd.m.Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  locale: 'tr',
                }},model:{value:(_vm.datasource.filter.start_date),callback:function ($$v) {_vm.$set(_vm.datasource.filter, "start_date", $$v)},expression:"datasource.filter.start_date"}})],1)]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group"},[_c('small',{staticClass:"text-sm text-uppercase font-semibold"},[_vm._v("Bitiş")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  enableTime: true,
                  altFormat: 'd.m.Y',
                  altInput: true,
                  dateFormat: 'Y-m-d',
                  locale: 'tr',
                }},model:{value:(_vm.datasource.filter.end_date),callback:function ($$v) {_vm.$set(_vm.datasource.filter, "end_date", $$v)},expression:"datasource.filter.end_date"}})],1)]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('small',{staticClass:"text-sm text-uppercase font-semibold"},[_vm._v("Takip Kodu")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.datasource.filter.tracking_code),expression:"datasource.filter.tracking_code"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.datasource.filter.tracking_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.datasource.filter, "tracking_code", $event.target.value)}}})])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('small',{staticClass:"text-sm text-uppercase font-semibold"},[_vm._v("Yön")]),_c('v-select',{attrs:{"options":[
                  { label: 'Müşteriden Gelen Kargo', value: 'take' },
                  { label: 'Müşteriye Giden Kargo', value: 'send' },
                  { label: 'Tümü', value: 'all' } ],"reduce":function (option) { return option.value; },"searchable":false,"clearable":false},model:{value:(_vm.datasource.filter.type),callback:function ($$v) {_vm.$set(_vm.datasource.filter, "type", $$v)},expression:"datasource.filter.type"}})],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('small',{staticClass:"text-sm text-uppercase font-semibold"},[_vm._v("Durum")]),_c('v-select',{attrs:{"options":[
                  { label: 'Beklemede', value: 'pending' },
                  { label: 'Tamamlandı', value: 'complated' } ],"reduce":function (option) { return option.value; },"searchable":false,"clearable":false},model:{value:(_vm.datasource.filter.status),callback:function ($$v) {_vm.$set(_vm.datasource.filter, "status", $$v)},expression:"datasource.filter.status"}})],1)]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group mt-2"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.getData()}}},[_vm._v(" Uygula ")])])])])]),_c('div',{staticClass:"firstlast-padding"},[_c('b-table',{ref:"table_task_list",staticClass:"mb-0 align-top",attrs:{"responsive":"","striped":"","small":"","hover":"","select-mode":"single","selectable":"","items":_vm.datasource.cargoList,"fields":_vm.datasource.table.fields,"tbody-tr-class":_vm.rowClass,"show-empty":""},on:{"row-selected":_vm.setTask},scopedSlots:_vm._u([{key:"cell(c_id)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(type)",fn:function(data){return [_c('div',{staticClass:"font-weight-bold"},[(data.value == 'take')?_c('span',[_vm._v("Gelen")]):_vm._e(),(data.value == 'send')?_c('span',[_vm._v("Giden")]):_vm._e()])]}},{key:"cell(code)",fn:function(data){return [_c('div',[_c('router-link',{attrs:{"to":{
                  name: 'cargotracking-detail',
                  query: { uniq: data.item.c_uniq },
                }}},[_vm._v(" "+_vm._s(data.item.cargo_tracking_code)+" ")])],1)]}},{key:"cell(customer_item)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.value.title)+" ")])]}},{key:"cell(city_item)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.value.city_name)+" ")])]}},{key:"cell(district_item)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.value.district_name)+" ")])]}},{key:"cell(office_item)",fn:function(data){return [_c('div',[_vm._v(" "+_vm._s(data.value.title)+" ")])]}},{key:"cell(staff_item)",fn:function(data){return [(data.value != null)?_c('div',[_vm._v(" "+_vm._s(data.value.user_namesurname)+" ")]):_vm._e(),(data.item.staff_item_complated != null)?_c('div',[_c('span',[_vm._v("Tamamlayan")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(data.item.staff_item_complated.user_namesurname))])]):_vm._e()]}},{key:"cell(date_item)",fn:function(data){return [_c('div',[_c('div',{staticClass:"font-medium"},[_vm._v("Planlanan T.")]),_c('div',[(_vm.moment(data.item.planned_date).isValid())?_c('span',[_vm._v(" "+_vm._s(_vm.moment(data.item.planned_date).format("DD.MM.YYYY HH:mm"))+" ")]):_vm._e()])]),(data.item.status == 'complated')?_c('div',{staticClass:"mt-05rem"},[_c('div',{staticClass:"font-medium"},[_vm._v("Tamamlanma T.")]),_c('div',[(_vm.moment(data.item.complated_date).isValid())?_c('span',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.moment(data.item.complated_date).format( "DD.MM.YYYY HH:mm" ))+" ")]):_c('span',[_vm._v("-")])])]):_vm._e()]}},{key:"cell(status_item)",fn:function(data){return [_c('status-badge',{attrs:{"status":data.value}})]}},{key:"empty",fn:function(){return [_c('div',{staticClass:"pt-3 pb-3 text-center"},[_vm._v(" Listelenecek bir kayıt bulunmuyor. ")])]},proxy:true}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-title"},[_vm._v("Kargolar")])])}]

export { render, staticRenderFns }