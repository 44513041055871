<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Kargolar</div>
      </div>
      <div class="card-body p-0">
        <div class="border-top border-bottom p-1">
          <div class="row">
            <div class="col-sm-2">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Başlangıç</small
                >
                <flat-pickr
                  class="form-control"
                  v-model="datasource.filter.start_date"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Bitiş</small
                >
                <flat-pickr
                  class="form-control"
                  v-model="datasource.filter.end_date"
                  :config="{
                    enableTime: true,
                    altFormat: 'd.m.Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Takip Kodu</small
                >
                <input
                  v-model="datasource.filter.tracking_code"
                  type="text"
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold">Yön</small>
                <v-select
                  v-model="datasource.filter.type"
                  :options="[
                    { label: 'Müşteriden Gelen Kargo', value: 'take' },
                    { label: 'Müşteriye Giden Kargo', value: 'send' },
                    { label: 'Tümü', value: 'all' },
                  ]"
                  :reduce="(option) => option.value"
                  :searchable="false"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold">Durum</small>
                <v-select
                  v-model="datasource.filter.status"
                  :options="[
                    { label: 'Beklemede', value: 'pending' },
                    { label: 'Tamamlandı', value: 'complated' },
                  ]"
                  :reduce="(option) => option.value"
                  :searchable="false"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mt-2">
                <button class="btn btn-primary" @click="getData()">
                  Uygula
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="firstlast-padding">
          <b-table
            ref="table_task_list"
            responsive
            striped
            small
            hover
            select-mode="single"
            selectable
            @row-selected="setTask"
            :items="datasource.cargoList"
            :fields="datasource.table.fields"

            :tbody-tr-class="rowClass"
            class="mb-0 align-top"
            show-empty
          >
            <template #cell(c_id)="data">
              {{ data.value }}
            </template>
            <template #cell(type)="data">
              <div class="font-weight-bold">
                <span v-if="data.value == 'take'">Gelen</span>
                <span v-if="data.value == 'send'">Giden</span>
              </div>
            </template>
            <template #cell(code)="data">
              <div>
                <router-link
                  :to="{
                    name: 'cargotracking-detail',
                    query: { uniq: data.item.c_uniq },
                  }"
                >
                  {{ data.item.cargo_tracking_code }}
                </router-link>
              </div>
            </template>
            <template #cell(customer_item)="data">
              <div>
                {{ data.value.title }}
              </div>
            </template>
            <template #cell(city_item)="data">
              <div>
                {{ data.value.city_name }}
              </div>
            </template>
            <template #cell(district_item)="data">
              <div>
                {{ data.value.district_name }}
              </div>
            </template>
            <template #cell(office_item)="data">
              <div>
                {{ data.value.title }}
              </div>
            </template>
            <template #cell(staff_item)="data">
              <div v-if="data.value != null">
                {{ data.value.user_namesurname }}
              </div>
              <div v-if="data.item.staff_item_complated != null">
                <span>Tamamlayan</span>
                <span class="ml-1">{{
                  data.item.staff_item_complated.user_namesurname
                }}</span>
              </div>
            </template>
            <template #cell(date_item)="data">
              <div>
                <div class="font-medium">Planlanan T.</div>
                <div>
                  <span v-if="moment(data.item.planned_date).isValid()">
                    {{
                      moment(data.item.planned_date).format("DD.MM.YYYY HH:mm")
                    }}
                  </span>
                </div>
              </div>
              <div class="mt-05rem" v-if="data.item.status == 'complated'">
                <div class="font-medium">Tamamlanma T.</div>
                <div>
                  <span class="text-success" v-if="moment(data.item.complated_date).isValid()">
                    {{
                      moment(data.item.complated_date).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </template>
            <template #cell(status_item)="data">
              <status-badge :status="data.value" />
            </template>
            <template #empty>
              <div class="pt-3 pb-3 text-center">
                Listelenecek bir kayıt bulunmuyor.
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    StatusBadge,
    VueAutosuggest,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      datasource: {
        cargoList: [],
        cargo: null,
        filter: {
          start_date: null,
          end_date: null,
          tracking_code: "",
          type: "all",
          status:"pending"
        },
        table: {
          fields: [
            {
              key: "type",
              label: "Yön",
              sortable: true,
              selected: true,
            },
            {
              key: "c_id",
              label: "No",
              sortable: true,
              selected: true,
            },
            {
              key: "code",
              label: "Takip Kodu",
              sortable: true,
              selected: true,
            },
            {
              key: "customer_item",
              label: "Müşteri",
              sortable: true,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: true,
              selected: true,
            },
            {
              key: "city_item",
              label: "İl",
              sortable: true,
              selected: true,
            },
            {
              key: "district_item",
              label: "İlçe",
              sortable: true,
              selected: true,
            },
            {
              key: "district_item",
              label: "İlçe",
              sortable: true,
              selected: true,
            },
            {
              key: "office_item",
              label: "Ofis",
              sortable: true,
              selected: true,
            },
            { key: "status_item", label: "Durum", sortable: true },
          ],
          selected: null,
        },
      },
    };
  },
  methods: {
    getData() {
      let postData = this.datasource.filter;
      api.action("getCargoList", { ...postData }).then((q) => {
        if (!q.success) {
          utils.notificationToast("warning", "Bilgi", q.message);
          return;
        }
        this.datasource.cargoList = q.data;
      });
    },
    setTask(item) {
      item = item[0];
      if (item) {
        this.sf.task = item;
        if (item.device_item != null) {
          this.sf.device = item.device_item;
        }
        if (item.delivery_item != null) {
          this.sf.delivery = item.delivery_item;
        }
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
    moment(v) {
      return moment(v);
    },
  },
  created() {
    this.datasource.filter.start_date = utils
      .moment()
      .add(-1, "years")
      .format("YYYY-MM-DD");
    this.datasource.filter.end_date = utils.moment().format("YYYY-MM-DD");
    this.getData();
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      cargo: (state) => state.cargo,
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>